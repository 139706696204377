import React, { useState } from "react";
import { MainLayout, Sidebar, Topbar } from "../components";
import { RenderIf } from "../../../components";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";


const DefaultLayouts = ({ children }) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const navigate=useNavigate();
  const state = useSelector((s) => s);

  const checkResetPassword = () => {
    if (location.pathname.split("/").includes("reset-password")) {
      return false;
    } else if (["/", "/signin"].includes(location.pathname)) {
      return false;
    } else if (isSidebar) {
      return isSidebar;
    }
  };


  return (
    <>
      <RenderIf isTrue={checkResetPassword()}>
        <Sidebar />
      </RenderIf>
      <RenderIf isTrue={checkResetPassword()}>
        <Topbar
          onCollapse={() => setIsSidebar((prev) => !prev)}
          isCollapse={checkResetPassword()}
        />
      </RenderIf>
      <MainLayout isCollapse={checkResetPassword()}>{children}</MainLayout>
    </>
  );
};

export default DefaultLayouts;
