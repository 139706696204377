import React from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Divider,
  Container,
  Center,
  Image,
} from "@chakra-ui/react";

const SubApplication = ({ applicationStatusList }) => {
  console.log(applicationStatusList);
  return (
    <>
      <Container bg="white" mt="15px" maxWidth="100%">
        <Heading as="h3" size="md" pt="12px" pb="20px">
          Application Activity Timeline
        </Heading>

        {applicationStatusList &&
          applicationStatusList.map(
            (applicationStatus, applicationStatusIndex) => {
              console.log(applicationStatus, applicationStatusIndex);
              return (
                <Box>
                  <Flex key={applicationStatusIndex}>
                    <Box w="5%">
                      <Center
                        bg="green"
                        borderRadius="50%"
                        width="102%"
                        height="28px"
                      >
                        <Image src={applicationStatus.icon} />
                      </Center>
                      <Center height="27px" mt="8px" mb="8px">
                        <Divider
                          orientation="vertical"
                          height="20px"
                          mt="1px"
                          mb="0"
                          borderColor="black"
                        />
                      </Center>
                    </Box>
                    <Box width="482px" ml="18px">
                      <Flex>
                        <Heading as="h6" size="xs">
                          {applicationStatus.title}
                        </Heading>
                        {applicationStatus.age_days != false ? (
                          <Box width="33%" alignSelf="center">
                            <Text fontSize="xs" pl="5px">
                              (Since {applicationStatus.age_days})
                            </Text>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Flex>
                      <Text fontSize="xs" mt="5px">
                        {applicationStatus.desc}
                      </Text>
                    </Box>
                    <Box width="33%" alignSelf="center">
                      <Text fontSize="xs" textAlign="end">
                        {applicationStatus.time}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              );
            }
          )}
      </Container>
    </>
  );
};

export default SubApplication;
