import React, { useState, useEffect } from "react";
import {
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Drawer,
  Td,
  Divider,
  Tag,
  Flex,
  Th, TableContainer, Table, Thead, Tr, Tbody, useToast, Text, Center
} from "@chakra-ui/react";
import "./showUrl.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "./../../../state";
import {
  useSearchParams,
  useNavigate
} from "react-router-dom";
import { Checkbox } from "rsuite";
import ModalAlert from "./modal";
import { allEndPoints } from "../../../services";


const ShowUrls = ({ open, onClose, urlList, id }) => {
  const state = useSelector((state) => state);
  const [updateUrl, setUpdateUrl] = useState();
  const [isResponse, setIsResponse] = useState(false);
  const [responseType, setResponseType] = useState('')
  const [searchParams] = useSearchParams();
  const [isLoad, setIsLoad] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const { fetchMorShowUrlReq } = bindActionCreators(
    actions,
    dispatch
  );

  useEffect(() => {
    if (searchParams.get('mor_id')) {
      fetchMorShowUrlReq(searchParams.get('mor_id'));
    }
  }, [searchParams]);

  useEffect(() => {
    if (state.morShowUrlReq.data) {
      setUpdateUrl(state.morShowUrlReq.data)
    }
  }, [state])

  // useEffect(() => {
  //   let selUrl = []
  //   if (updateUrl && updateUrl.urls) {
  //     updateUrl.urls.forEach((durl) => {
  //       if (parseInt(durl.status) === 1) {
  //         // selUrl.push(durl['_id'])
  //         selUrl.push(durl['_id'])
  //       }
  //     })
  //     setSelectedUrl(selUrl)
  //   }
  // }, [updateUrl])


  useEffect(() => {
    fetchMorShowUrlReq()
  }, [])


  const handleSelectedUrl = (event) => {
    setSelectedUrl((prev) => {
      prev = [...prev]
      if (prev.includes(event)) {
        prev.splice(prev.indexOf(event), 1)
      } else {
        prev.splice(prev.indexOf(event), 0)
        prev.push(event)
      }
      return prev
    })
  }

  // useEffect(() => {
  //   console.log("iid-",id)
  //   allEndPoints.fetchMorShowUrlReq(id);
  // }, [id,urlList]);

  useEffect(() => {
    console.log(state)
  }, [state])

  const handleDeactivteUrl = () => {
    setIsLoad(true);
    allEndPoints.removeMorUrls(searchParams.get('mor_id'),
      { 'requestType': 0, 'urlsIds': selectedUrl })
      .then(() => {
        // navigate('')
        setIsLoad(false)
        setIsResponse(true)
        setResponseType('success')
        setTimeout(() => {
          setIsResponse(false)
        }, 3000)
        toast({
          status: "success",
          title: 'Your request has been submitted',
          position: "top",
        });
      }).catch(() => {
        setIsResponse(true)
        setResponseType('error')
        setTimeout(() => {
          setIsResponse(false)
        }, 3000)
        setIsLoad(false);
        toast({
          status: "error",
          title: 'Something went wrong',
          position: "top",
        });
      })
  };

  if (!open) return null;


  return (

    <Drawer isOpen={open} placement="right" onClose={onClose} size="lg"   >

      <DrawerOverlay />
      <DrawerContent style={{
        overflow: "scroll",
        overflowX: "hidden",
        height: "auto",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}>


        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #818181"
          padding="8px"
        >
          <Text fontSize="28px" fontWeight="bold"> Urls</Text>
          <>{state.morShowUrlReq.data && state.morShowUrlReq.data.data && state.morShowUrlReq.data.data.urls[0]}</>
          <DrawerCloseButton position="none" />
        </DrawerHeader>
        <Text fontSize="21px" fontWeight="bold" marginLeft="10px"><u>Active Url List</u> </Text>

        <Box p={4}>
          {/* <UnorderedList spacing={3}>
            <div
              onClick={() => {
                // setUpdateUrl(true)
                console.log(state.morUrlList.data)
              }}
            >
              {id}
            </div>
            {state.morUrlList.data &&
              state.morUrlList.data.data &&
              state.morUrlList.data.data.map((d) => (
                // <ListItem key={data}>{data}</ListItem>
                <div onClick={() => { console.log(d) }}>list</div>
              ))}
            <ListItem> {updateUrl && updateUrl.urls && updateUrl.urls.map((url) => (
              url.url 
            ))}   </ListItem>
          </UnorderedList> */}

          <TableContainer >
            <Table variant="simple"  >
              <Thead>
                <Tr >
                  <Th >Action</Th>
                  <Th>Sector</Th>
                  <Th>Url</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>

              <Tbody>
                {updateUrl.urls &&
                  updateUrl.urls.length < 1 ? (
                    <Center w="100%" h="100px">
                    <h4 style={{color:'red', textAlign:'center'}}>"No records found"</h4>
                  </Center>
                ) : (
                  updateUrl && updateUrl.urls && updateUrl.urls.map((d) => {
                    return (parseInt(d.status) === 1 &&
                      <Tr key={d['_id']}>
                        <Td className="CheckboxTable"><Flex>
                          <Flex alignItems={'center'}>
                            <Checkbox colorScheme='green' checked={selectedUrl.includes(d['_id'])}
                              onChange={handleSelectedUrl} value={d['_id']} name={d['_id']}
                            />
                          </Flex>

                        </Flex>
                        </Td>
                        <Td>{d.sector} </Td>
                        <Td>{d.url}</Td>
                        <Td> <Flex alignItems={'center'}>
                          <Tag ml={2} colorScheme='green' >Active</Tag>
                        </Flex></Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>

            </Table>
          </TableContainer>

          <Divider style={{ marginTop: "29px", borderBottom: "1px solid #818181" }} />
          <ModalAlert isDisabled={selectedUrl.length === 0} onConfirm={handleDeactivteUrl}
            isLoading={isLoad} isResponse={isResponse} responseType={responseType} />
          <Text fontSize="21px" fontWeight="bold"><u>In-active Url List</u> </Text>
          <TableContainer mt={30} >
            <Table variant="simple"  >
              <Thead>
                <Tr >
                  <Th>Sector</Th>
                  <Th>Url</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {updateUrl.urls &&
                  updateUrl.urls.length < 1 ? (
                    <Center w="100%" h="100px">
                    <h4 style={{color:'red',textAlign:'center'}}>"No records found"</h4>
                  </Center>
                ) : (
                  updateUrl && updateUrl.urls && updateUrl.urls.map((d) => {
                    return (parseInt(d.status) === 0 &&
                      <Tr key={d['_id']}>
                        {/* <Td className="CheckboxTable"><Flex>
                          <Flex alignItems={'center'}>
                          </Flex>

                        </Flex>
                        </Td> */}
                        <Td>{d.sector} </Td>
                        <Td>{d.url}</Td>
                        <Td> <Flex alignItems={'center'}>
                          <Tag ml={2} colorScheme='red'>In-active </Tag>
                        </Flex></Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
export default ShowUrls;


