import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* loginPartnerInfo(data) {
  try {
    const result = yield call(allEndPoints.loginPartnerInfo);
    yield put(actions.recieveLoginPartnerInfo(result.data.data));
  } catch (error) {
    yield put(actions.failLoginPartnerInfo);
  }
}

function* watchLoginPartnerInfo() {
  yield takeEvery("FETCH_LOGIN_PARTNER_INFO", loginPartnerInfo);
}

const loginPartnerInfoSagas = [fork(watchLoginPartnerInfo)];

export default loginPartnerInfoSagas;
