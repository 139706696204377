import styled from "styled-components";

export const TableDiv = styled.div`
border-radius: 15px;
background:#fff;
.css-1zts0j{
    color:#436A6A,
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
line-height: 24px;
}
.row-id{
    color:#818181;
    width: fit-content;
    
}
}
.action{
    color:#4176FF;
    cursor:pointer;
    &:hover{
        text-decoration: underline;
    }
}
td{
    font-size: 14px;
    width: fit-content;
}

}
`;

export const StatusCircle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-top: 6px;
`;
export const HedingDiv = styled.div`
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  
  h2 {
    font-weight: 600;
    font-size: 22px;
  }
  p {
    color: #818181;
    font-size: 13px;
  }
  button {
    background: #436a6a;
    color: white;
    &:hover {
      background: #436a6aad;
    }
  }
`;
export const SearchFields= styled.div`
width: 100%;

padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  .search-btn{
    justify-content: flex-end;
    display: flex;
      width: 25%;
      button{
        margin: 0 5px;
      }
  }
  .filter-field{
    display: flex;
    width: 70%;

    span{
      width:20rem;
      // min-width: 200px;
      // max-width: 300px;
      padding: 0 5px;
      .css-10ex9a1{
        padding: 5px 10px !important;
      }
      .css-56hxd5 {
        padding: 5px 10px !important;}
        .css-zg1b4 {
          padding: 5px 10px !important;
        }
    }
  }

`
export const SearchBtton= styled.div`
padding: 0 25px;
justify-content: flex-end;
display: flex;
  width: 25%;
  button {
    padding: 0 25px;
    background: #436a6a;
    color: white;
    &:hover {
      background: #436a6aad;
    }
  }
`

export const PaginationDiv = styled.div`
  padding: 10px 24px;
`;

export const FIiconDiv =styled.div`
svg{
  font-size:35px;
  margin-Left:10px;
  margin-top:20px;
}


`
