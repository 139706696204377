import React from "react";
import {
  Container,
  Flex,
  Avatar,
  Box,
  Link,
  Divider,
  Center,
  Heading,
} from "@chakra-ui/react";

const PartnerInfo = ({ partnerInfo }) => {
  return (
    <Container bg="white">
      <Center>
        <Flex gridGap="10px" mt="31px">
          <Avatar
            name={
              partnerInfo && partnerInfo.ownerInfo && partnerInfo.ownerInfo.name
            }
          />

          <Box>
            <Heading mt="15px" as="h3" size="sm">
              {partnerInfo &&
                partnerInfo.ownerInfo &&
                partnerInfo.ownerInfo.name}
            </Heading>
          </Box>
        </Flex>
      </Center>

      <Center mt="10px">
        <Link color="blue">
          {partnerInfo.ownerInfo && partnerInfo.ownerInfo.email}
        </Link>
      </Center>
      <Divider mt="10px" color="black" />
      <Center>
        <text ml="5px"> Status :</text>
        <text style={{ marginLeft: "10px", color: "green" }}>
          {partnerInfo.status}
        </text>
      </Center>
    </Container>
  );
};

export default PartnerInfo;
