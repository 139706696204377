import User from "./user";
import Key from "./key";
import Bento from "./bento";
import HameBurger from "./hamburger";
import Filter from './filter'


const Icons = {
  user: User,
  bento: Bento,
  hameBurger: HameBurger,
  key: Key,
  filter:Filter,
};

export default Icons;
