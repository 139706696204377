import React, { useState, useEffect } from "react";
import {
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Drawer,
  Text,
  Button,
  Center
} from "@chakra-ui/react";

import FTIcon from "react-file-type-icons";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../state";
import {
  useSearchParams,
  useNavigate, createSearchParams,

} from "react-router-dom";

import { allEndPoints } from "../../../services";
import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import { FIiconDiv } from "./style";


const DownloadAttachment = ({ open, onClose, urlList, id, data }) => {
  const state = useSelector((state) => state);
  const [isDocuments, setIsDocuments] = useState([]);
  const [searchParams] = useSearchParams();
  const [isDownload, setIsDownload] = useState(false)
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { fetchMorDownloadAttachment, fetchMorCompanyDocuments } = bindActionCreators(
    actions,
    dispatch
  );


  useEffect(() => {

    if (searchParams.get('mor_documents')) {
      fetchMorCompanyDocuments(searchParams.get('mor_documents'));
    }
  }, [searchParams]);


  useEffect(() => {
    console.log(state)
    if (state.morCompanyDocuments.data) {
      setIsDocuments(state.morCompanyDocuments.data.documents)
    }
  }, [state])


  const handleDownloadAttachment = (key) => {
    fetchMorDownloadAttachment(key)
    console.log(state.morDownloadAttachment.data, "124fhgfduihguifdhng")
    if (state.morDownloadAttachment.data && state.morDownloadAttachment.data.data) {
      window.open(state.morDownloadAttachment.data.data?.url, "_blank")
    }
    // window.location.href ();
  }

  if (!open) return null;


  return (

    <Drawer isOpen={open} placement="right" onClose={onClose} size="lg"   >

      <DrawerOverlay />
      <DrawerContent style={{
        overflow: "scroll",
        overflowX: "hidden",
        height: "auto",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}>


        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #818181"
          padding="8px"
        >
          <Text fontSize="28px" fontWeight="bold"> View Documents</Text>
          <DrawerCloseButton position="none" />
        </DrawerHeader>

        {state.morCompanyDocuments.data && state.morCompanyDocuments.data.documents &&
         state.morCompanyDocuments.data.documents.length < 1 ? (
          <Center w="100%" h="100px">
            <h3 style={{color:'red',textAlign:'center'}}>"No records found"</h3>
          </Center>
        ) : (

          state.morCompanyDocuments.data && state.morCompanyDocuments.data.documents
          && state.morCompanyDocuments.data.documents.map((d) => {

            return <div  >

              <FIiconDiv>
                <FTIcon
                  fileName={d?.name?.split(".")?.pop() || "url"}
                  style={{ fontSize: "80px" }}
                  color="#2b6b2a"
                  fileType={"url"}

                />
              </FIiconDiv>

              <div style={{ marginLeft: "70px", marginTop: "-35px", fontSize: "16px", fontWeight: "bold" }}>

                {/* <a href={d.name} target="_blank"  > */}
                <a target="_blank" onClick={() => {
                  console.log(d.key, "12356468745646874")
                  handleDownloadAttachment(d.key)
                  setIsDownload(true)
                }
                } >
                  {d.name}
                </a>

                <div style={{ marginTop: "-24px", textAlign: "center" }}>
                  <Button
                    size="sm" variant="solid" width="12%"
                    onClick={() => {
                      console.log(d.key, "12356468745646874")
                      handleDownloadAttachment(d.key)
                      setIsDownload(true)
                    }
                    }
                  >
                    {/* <DownloadIcon /> */}
                    <ViewIcon />

                    View
                  </Button>
                </div>

              </div>
            </div>
          }))}

      </DrawerContent>
    </Drawer>
  );
};
export default DownloadAttachment;


