import React, { useState } from "react";
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { allEndPoints } from "../../../../services";
import "./forgotpassword.css";
// import { useNavigate } from "react-router-dom";

const Forgotpasswordmail = ({ onCancel }) => {
  const [forgotpassworData, setForgotpassworData] = useState({
    email: "",
  });
  const toast = useToast();
  const [isLoad, setIsLoad] = useState(false);
  // const navigate = useNavigate();

  const forgotpassworDataHandal = (event) => {
    setForgotpassworData((prev) => {
      prev = { ...prev };
      prev[event.target.name] = event.target.value;
      return prev;
    });
  };

  const handleFormData = (event) => {
    event.preventDefault();
    if (forgotpassworData && forgotpassworData.email == "") {
      toast({
        status: "error",
        title: "Email field is required",
        position: "top",
      });
      return false;
    }
    setIsLoad((prev) => !prev);
    allEndPoints
      .forgetPassword(forgotpassworData)
      .then((res) => {
        setIsLoad((prev) => !prev);
        toast({
          status: "success",
          title: "An email has been sent to your mail id",
          position: "top",
        });
      })
      .catch((err) => {
        setIsLoad((prev) => !prev);
        toast({
          status: "error",
          title:
            "Either your email id does't exist or you are entering incorrect email id",
          position: "top",
        });
      });
  };

  return (
    <form onSubmit={handleFormData} id="forgotPassword">
      <Flex>
        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            Forgot your password?
          </Heading>
          <Text
            fontSize={{ base: "sm", sm: "md" }}
            color={useColorModeValue("gray.800", "gray.400")}
          >
            You&apos;ll get an email with a reset link
          </Text>
          <FormControl>
            <Input
              placeholder="your-email@example.com"
              _placeholder={{ color: "gray.500" }}
              type="email"
              name="email"
              value={forgotpassworData.email}
              onChange={forgotpassworDataHandal}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
              isLoading={isLoad}
              type="submit"
            >
              Reset
            </Button>
            <Button
              bg={"gray.400"}
              color={"black"}
              isLoading={isLoad}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </form>
  );
};
export default Forgotpasswordmail;
