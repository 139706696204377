import React from "react";
import {
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer,
  Stack,
  Select,
  DrawerFooter,
  Button,
  Grid,
} from "@chakra-ui/react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import FormLabels from "./formLabels";
import Inputs from "./inputs";
import { useState } from "react";
import { useEffect } from "react";

const SearchFilter = ({ open, onClose }) => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let dupFormData = { ...formData };
    let querryArray = ["status", "deal_id", "bussiness_name",'stage'];
    querryArray.forEach((q) => {
      if (searchParams.get(q)) {
        dupFormData[q] = searchParams.get(q);
      }
    });
    console.log(dupFormData)
    setFormData((prev) => ({ ...prev, ...dupFormData }));
  }, [searchParams]);

  const handleFormData = (event) => {
    console.log("formdata", event);
    setFormData((prev) => {
      prev = { ...prev };
      prev[event.target.name] = event.target.value;
      return prev;
    });
  };

  const setNav = () => {
    console.log(formData)
    navigate({
      pathname: "",
      search: createSearchParams({
        ...formData,
        page: 1,
        limit: 10,
      }).toString(),
    });
    onClose(true);
  };
  const dealstages = ["Any","Approach / Fact Find","Pre Approval In Progress","Compliance","Application","Boarding","Bank Decision","Integration","Closed Won","Closed Lost"]

  if (!open) return null;
  return (
    <Drawer isOpen={open} placement="right" onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #818181"
          padding="8px"
        >
          <h5>Search Filter</h5>
          <DrawerCloseButton position="none" />
        </DrawerHeader>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            setNav();
          }}
        >
          <DrawerBody mt="39px">
            <Stack spacing="24px">
              <Box>
                <Select
                  borderColor="black"
                  height="52px"
                  id="owner"
                  name="status"
                  onChange={handleFormData}
                >
                  <option
                    value="any"
                    selected={formData.status === "any" && true}
                  >
                    Any
                  </option>
                  <option
                    value="preapproval"
                    selected={formData.status === "preapproval" && true}
                  >
                    Pre-approval
                  </option>
                  <option
                    value="active"
                    selected={formData.status === "active" && true}
                  >
                    Active
                  </option>
                  <option
                    value="trading"
                    selected={formData.status === "trading" && true}
                  >
                    Trading
                  </option>
                  <option
                    value="lost"
                    selected={formData.status === "lost" && true}
                  >
                    Lost
                  </option>
                </Select>
              </Box>

              <Box position="relative" zIndex={1}>
                <FormLabels type="Business Name" />
                <Inputs
                  placeholder="Search here"
                  name="bussiness_name"
                  onChange={handleFormData}
                  value={formData.bussiness_name && formData.bussiness_name}
                />
              </Box>

              <Box>
                <Select
                  borderColor="black"
                  height="52px"
                  id="stage"
                  name="stage"
                  onChange={handleFormData}
                >
                 {
                  dealstages.map((ds,dsI)=>(
                    <option value={ds} key={dsI}>{ds}</option>
                  ))
                 }
                </Select>
              </Box>
            </Stack>
          </DrawerBody>

          <Grid>
            <Button
              ml="22px"
              mr="22px"
              bg="#436A6A"
              color="white"
              type="submit"
              colorScheme="teal"
              mt="15px"
            >
              Submit
            </Button>
            <Button
              mt="15px"
              ml="22px"
              mr="22px"
              bg="#CE2828"
              color="white"
              colorScheme="red"
              onClick={() => {
                navigate({
                  pathname: "",
                  search: createSearchParams({
                    page: 1,
                    limit: 10,
                  }).toString(),
                });
                setFormData({});
                onClose();
              }}
            >
              Reset
            </Button>
          </Grid>
        </form>

        <DrawerFooter borderTopWidth="1px"></DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SearchFilter;
