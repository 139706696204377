// PARTNER APPLICATIONS LISTs
export const fetchPartnerApplicationsList = (querry = "") => {
  console.log(querry)
  return {
  type: "FETCH_PARTNER_APPLICATIONS_LIST",
  querry,
}};

export const recievePartnerApplicationsList = (data) => ({
  type: "RECIEVE_PARTNER_APPLICATIONS_LIST",
  payload: { ...data },
});

export const failPartnerApplicationsList = () => ({
  type: "FAIL_PARTNER_APPLICATIONS_LIST",
});

// MOR APPLICATION LIST

export const fetchMorApplicationsList = (querry = "") => {
  console.log(querry)
  return {
  type: "FETCH_MOR_APPLICATIONS_LIST",
  querry,
}};

export const recieveMorApplicationsList = (data) => ({
  type: "RECIEVE_MOR_APPLICATIONS_LIST",
  payload: { ...data },
});

export const failMorApplicationsList = () => ({
  type: "FAIL_MOR_APPLICATIONS_LIST",
});
// MOR URL LIST

export const fetchMorUrlList = (id) => {
  // console.log(id)
  return {
  type: "FETCH_MOR_URL_LIST",
  
}};

export const recieveMorUrlList = (data) => ({
  type: "RECIEVE_MOR_URL_LIST",
  payload: { ...data },
});

export const failMorUrlList = () => ({
  type: "FAIL_MOR_URL_LIST",
});

// MOR SOLUTION APPLICATION LIST

export const fetchMorSolList = (querry = "") => {
  console.log(querry)
  return {
  type: "FETCH_MOR_SOL_LIST",
  querry,
}};

export const recieveMorSolList = (data) => ({
  type: "RECIEVE_MOR_SOL_LIST",
  payload: { ...data },
});

export const failSolList = () => ({
  type: "FAIL_MOR_SOL_LIST",
});

// MOR PARTNER COMPANY SOLUTION LIST

export const fetchMorPartnerSolList = (querry = "") => {
  console.log(querry)
  return {
  type: "FETCH_MOR_PARTNER_COMPANY_SOLUTION",
  querry,
}};

export const recieveMorPartnerSolList= (data) => ({
  type: "RECIEVE_MOR_PARTNER_COMPANY_SOLUTION",
  payload: { ...data },
});

export const failMorPartnerSolList = () => ({
  type: "FAIL_MOR_PARTNER_COMPANY_SOLUTION",
});



// MOR SHOW ADD REQUEST URL

export const fetchMorShowUrlReq = (id) => {
  console.log(id,"zolute")
  return {
  type: "FETCH_MOR_SHOW_URL_REQ",
 id,
}};

export const recieveMorShowUrlReq = (data) => ({
  type: "RECIEVE_MOR_SHOW_URL_REQ",
  payload: { ...data },
});

export const failMorShowUrlReq = () => ({
  type: "FAIL_MOR_SHOW_URL_REQ",
});

// MOR  REQUEST URL

export const fetchMorUrlReq = (id) => {
  console.log(id,"id")
  return {
  type: "FETCH_MOR_URL_REQ",
 id,
}};

export const recieveMorUrlReq = (data) => ({
  type: "RECIEVE_MOR_URL_REQ",
  payload: { ...data },
});

export const failMorUrlReq = () => ({
  type: "FAIL_MOR_URL_REQ",
});


// MOR MY URL REQUEST 

export const  fetchMorMyUrlRequest = (querry = "") => {
  console.log(querry)
  return {
  type: "FETCH_MOR_MY_URL_REQUEST",
  querry,
}};

export const recieveMorMyUrlRequest = (data) => ({
  type: "RECIEVE_MOR_MY_URL_REQUEST",
  payload: { ...data },
});

export const failMorMyUrlRequest = () => ({
  type: "FAIL_MOR_MY_URL_REQUEST",
});

// MOR Download Attachment
export const  fetchMorDownloadAttachment = (data) => {
  // console.log(data)
  return {
  type: "FETCH_MOR_DOWNLOAD_ATTACHMENT",
  data,
}};

export const recieveMorDownloadAttachment = (data) => ({
  type: "RECIEVE_MOR_DOWNLOAD_ATTACHMENT",
  payload: { data },
});

export const failMorDownloadAttachment = () => ({
  type: "FAIL_MOR_DOWNLOAD_ATTACHMENT",
});


// mor company-documents
export const fetchMorCompanyDocuments = (deal_id) => ({
  type: "FETCH_MOR_COMPANY_DOCUMENTS",
  deal_id,
});

export const recieveMorCompanyDocuments = (data) => ({
  type: "RECIEVE_MOR_COMPANY_DOCUMENTS",
  payload: data,
});

export const failMorCompanyDocuments = () => ({
  type: "FAIL_MOR_COMPANY_DOCUMENTS",
});




// PARTNER APPLICATION DETAILS
export const fetchPartnerApplicationDetails = (deal_id) => ({
  type: "FETCH_PARTNER_APPLICATION_DETAILS",
  deal_id,
});

export const recievePartnerApplicationDetails = (data) => ({
  type: "RECIEVE_PARTNER_APPLICATION_DETAILS",
  payload: data,
});

export const failPartnerApplicationDetails = () => ({
  type: "FAIL_PARTNER_APPLICATION_DETAILS",
});

//Partner Application Counts///
export const fetchPartnerApplicationCounts = (data) => ({
  type: "FETCH_PARTNER_APPLICATION-COUNTS",
  data,
});

export const recievePartnerApplicationCounts = (data) => ({
  type: "RECIEVE_PARTNER_APPLICATION_COUNTS",
  payload: data,
});
export const failPartnerApplicationCounts = () => ({
  type: "FAIL_PARTNER_APPLICATION_COUNTS",
});

//// subApplicationTimeLine   ////
export const fetchSubApplicationTimeLine = (data) => ({
  type: "FETCH_PARTNER_SUBAPPLICATIONS_TIMELINE",
  data,
});

export const recieveSubApplicationTimeLine = (data) => ({
  type: "RECIEVE_PARTNER_SUBAPPLICATIONS_TIMELINE",
  payload: data,
});
export const failSubApplicationTimeLine = () => ({
  type: "FAIL_PARTNER_SUBAPPLICATIONS_TIMELINE",
});

////  loginPartnerInfo /////

export const fetchLoginPartnerInfo = (data) => ({
  type: "FETCH_LOGIN_PARTNER_INFO",
  data,
});

export const recieveLoginPartnerInfo = (data) => ({
  type: "RECIEVE_LOGIN_PARTNER_INFO",
  payload: data,
});
export const failLoginPartnerInfo = () => ({
  type: "FAIL_LOGIN_PARTNER_INFO",
});
