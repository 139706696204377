import React from "react";
import { Box } from "@chakra-ui/react";

const Circle = (props) => {
  return (
    <Box
      borderRadius="50%"
      width="350px"
      height="350px"
      position="absolute"
      border="40px solid  rgba(222, 222, 222, 0.08)"
      {...props}
    ></Box>
  );
};

export default Circle;
