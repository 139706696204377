import React, { useEffect } from "react";
import { allEndPoints } from "../../services";
import { useNavigate, useLocation } from "react-router-dom";

const Middleware = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  //   const { forget_id } = useParams();

  useEffect(() => {
    if (
      !["/", "/signin"].includes(location.pathname) &&
      location.pathname.split("/")[1] !== "reset-password"
    ) {
      localStorage.setItem("prevUrl", location.pathname);

      allEndPoints

        .loginPartnerInfo()

        .then((res) => {})

        .catch((err) => {
          navigate("/signin");
        });
    }
  }, [location]);

  return <>{children}</>;
};

export default Middleware;
