import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* partnerApplicationsList(data) {
  try {
    const result = yield call(
      allEndPoints.partnerApplicationsList,
      data.querry
    );
    yield put(actions.recievePartnerApplicationsList({ ...result.data }));
  } catch (error) {
    yield put(actions.failPartnerApplicationsList);
  }
}

function* watchPartnerApplicationsList() {
  yield takeEvery("FETCH_PARTNER_APPLICATIONS_LIST", partnerApplicationsList);
}

const applicaionsListSagas = [fork(watchPartnerApplicationsList)];

export default applicaionsListSagas;
