import * as React from "react";

const User = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19 .667C29.12.667 37.333 8.88 37.333 19S29.12 37.333 19 37.333.667 29.12.667 19 8.88.667 19 .667ZM8.042 25.263c2.691 4.015 6.732 6.57 11.251 6.57 4.518 0 8.56-2.553 11.25-6.57a16.442 16.442 0 0 0-11.25-4.43 16.442 16.442 0 0 0-11.25 4.43ZM19 17.167a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z" />
  </svg>
);

export default User;
