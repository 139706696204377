
import * as React from "react";


const Bento = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    
    {...props}
  >
    <path
      d="M6.25 8.75a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM15 8.75a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM23.75 8.75a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM6.25 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM15 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM23.75 17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM6.25 26.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM15 26.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM23.75 26.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
    />
  </svg>

);

export default Bento;