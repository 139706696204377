import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import styles from "./sidebar.module.css";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const LinkContainer = ({links, height}) => {
    return(
        <Grid 
            templateColumns='repeat(1, 1fr)' 
            gap={2} 
            overflowX="hidden" 
            overflowY="auto"
            height="auto"
            mt={2}
            className={cx('link-container')}
        >
            {links.map((link,linkIndex)=>(
                <GridItem key={linkIndex}>
                    {link}
                </GridItem>
            ))}
        </Grid>
    )
}

export default LinkContainer;