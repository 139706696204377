import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "access-control-allow-origin": "*",



  Authorization: `Bearer ${localStorage.getItem("partner_token")}`,
};

const ems = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers,
});

export { ems };
