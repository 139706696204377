import React, { useState } from "react";
import { Input, Circle, Forgotpasswordmail } from "../components";
import { Link, useLocation } from "react-router-dom";
import {
  Flex,
  Button,
  Container,
  Text,
  Image,
  Spacer,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  useDisclosure,
  InputGroup,
  InputRightElement,
  Center,
} from "@chakra-ui/react";
import brandlogo from "../../../assets/brandlogo.png";
import { useNavigate } from "react-router-dom";
import { allEndPoints } from "../../../services";
import { useToast } from "@chakra-ui/react";
import { actions } from "../../../state";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const Signin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoad, setIsLoad] = useState(false);
  const toast = useToast();
  const statuses = ["success", "error", "warning", "info"];
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  const { fetchLogin } = bindActionCreators(actions, dispatch);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleFormData = (event) => {
    setFormData((prev) => {
      prev = { ...prev };
      prev[event.target.name] = event.target.value;
      return prev;
    });
  };

  useEffect(() => {
    if (localStorage.getItem("partner_token")) {
      if (state.loginPartnerInfo.data && state.loginPartnerInfo.data.type === 'MOR') {
        navigate("/my-trading-account-list");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);


  const handleLogin = (event) => {
    event.preventDefault();
    if (formData && formData.email == "") {
      toast({
        status: "error",
        title: "Email field is required",
        position: "top",
      });
      return false;
    }
    setIsLoad((prev) => !prev);
    allEndPoints
      .partnerLogIn(formData)
      .then((res) => {
        setIsLoad((prev) => !prev);
        localStorage.setItem("partner_token", res.data.token);
        localStorage.setItem("partner_type", res.data.data.type);
        if(res.data.data.type==='MOR'){
          navigate('/my-trading-account-list')
        }else{
          navigate('/dashboard')
        }
        window.location.reload();
      })
      .catch((err) => {
        setIsLoad((prev) => !prev);
        console.log(err);
        toast({
          title: err.response.data.msg,
          status: "error",
          position: "top",
          isClosable: true,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleLogin}>
        <Flex w="100%" h="100vh">
          <Flex
            w="45%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Container>
              <Image height="67px" src={brandlogo} alt="Dan Abramov" />
              <Stack mt={10} spacing={10}>
                <Input
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormData}
                />

                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    value={formData.password}
                    onChange={handleFormData}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Stack>
              <Flex mt={5}>
                <Spacer />

                <Link
                  style={{
                    color: "var(--text-link)",
                    textDecoration: "underline",
                  }}
                  to=""
                  onClick={onOpen}
                >
                  Forgot Password?
                </Link>
              </Flex>

              <Button
                mt={8}
                color="#FFFFFF"
                justifyContent="center"
                backgroundColor="#436A6A"
                borderRadius={30}
                w="100%"
                variant="solid"
                isLoading={isLoad}
                size="md"
                _hover={{
                  color: "#FFFFFF",
                  backgroundColor: "#436A6A",
                }}
                _focus={{
                  color: "#FFFFFF",
                  backgroundColor: "#436A6A",
                }}
                // onClick={returnToDashboard}

                type="submit"
              >
                Login
              </Button>

              {/* </form> */}
            </Container>
          </Flex>

          <Flex
            w="55%"
            bg="#000"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            pl={6}
            position="relative"
            overflow="hidden"
          >
            <Circle top={-40} right={-40} />
            <Circle bottom={-40} left={-40} />
            <Center w="100%">
              <Text fontSize="70px" fontWeight={700} color="var(--text-white)">
                Welcome
              </Text>
            </Center>
            <Center w="100%">
              <Text fontSize="70px" fontWeight={700} color="var(--text-white)">
                EMS Partner Portal
              </Text>
            </Center>
          </Flex>
        </Flex>
      </form>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Forgotpasswordmail onCancel={onClose} />
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Signin;
