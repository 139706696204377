import { all } from "redux-saga/effects";
import applicaionListSagas from "./applicaionListSagas";
import applcationDetailsSagas from "./applcationDetailsSagas";
import subApplicationTimeLineSagas from "./applicationTimeLine";
import loginPartnerInfoSagas from "./loginPartnerInfoSaga";
import partnerCountSagas from "./partnerCountSaga";
import morApplicationsListSagas from "./morApplicationListSagas"
import morSolListSagas from "./morSolListSagas";
import morUrlListSagas from "./morUrlListSagas";
import morShowUrlReqSagas from "./morShowUrlReqSaga";
import morPartnerSolListSagas from "./morPartnerSolutionCompanySagas";
import morMyUrlRequestSagas from "./morMyUrlRequestSagas";
import morUrlReqSagas from "./morUrlReqSagas";
import morDownloadAttachmentSagas from "./morDownloadAttachmentSagas";
import morCompanyDocumentsSagas from "./morCompanyDocumentsSagas";

export default function* rootSaga() {
  yield all([
    ...applicaionListSagas,
    ...applcationDetailsSagas,
    ...subApplicationTimeLineSagas,
    ...loginPartnerInfoSagas,
    ...partnerCountSagas,
    ...morApplicationsListSagas,
    ...morSolListSagas,
    ...morUrlListSagas,
    ...morShowUrlReqSagas,
    ...morPartnerSolListSagas,
    ...morMyUrlRequestSagas,
    ...morUrlReqSagas,
    ...morDownloadAttachmentSagas,
    ...morCompanyDocumentsSagas,
  ]);
}
