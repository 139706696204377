import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* partnerApplicationCounts() {
  try {
    const result = yield call(allEndPoints.partnerApplicationCounts);
    yield put(actions.recievePartnerApplicationCounts(result.data.data));
  } catch (error) {
    yield put(actions.failPartnerApplicationCounts);
  }
}

function* watchPartnerApplicationCounts() {
  yield takeEvery("FETCH_PARTNER_APPLICATION-COUNTS", partnerApplicationCounts);
}

const partnerCountSagas = [fork(watchPartnerApplicationCounts)];

export default partnerCountSagas;
