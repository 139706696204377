import React from "react";
import { Flex } from "@chakra-ui/react";
import { Loader } from "rsuite";

const FullLoader = () => {
  return (
    <Flex
      width={"100%"}
      height={"100vh"}
      position="fixed"
      top={0}
      left={0}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      background="blackAlpha.700"
      zIndex="1024"
    >
      <Loader inverse center content="loading..." />
    </Flex>
  );
};

export default FullLoader;
