import React, { useMemo, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import "./document-upload.css";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { morUploadAttachment } from '../../../services/apis';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';

const DocumentUpload = ({
    name,
    objectKeyName,
    label,
    onChange,
    extensions,
    isObject,
    onClose,
}) => {


    const [fileData, setFileData] = useState({ status: false, name: '' })
    const [done, setDone] = useState(null)
    const [error, setError] = useState('')

    const progressStyle = useMemo(() => {

        return {

            opacity: 1,

            width: `${done ? 100 : 0}%`

        }

    }, [done]);

    const handleFileChange = (event) => {
        setError('')
        const newFile = event.target.files[0]
        const extn = newFile.name.split('.').pop()
        if (extensions.includes(extn)) {
            console.log('dmsldslldsl')
            let formData = new FormData()
            formData.append("media", newFile)
            setDone(0)
            morUploadAttachment(formData).then((res) => {
                onChange({ target: { name, value: isObject ? { name: objectKeyName, key: res.data.key } : res.data.key } })
                setDone(100)
                setTimeout(() => {
                    setFileData({ status: true, name: res.data.fileName, key: res.data.key })
                    setDone(null)
                }, 1000)
            }).catch((err) => {
                console.log(err)
            })
        } else {
            setError(`This file extension is not allowed. Allowed extensions(${extensions})`)
        }
    }


    return (

        <div>
            <Text mb={1} >{label} <span style={{ color: "red" }} >* </span></Text>
            <Flex
                flexDirection={'column'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                position={'relative'}
                className={`file-upload ${fileData.status ? 'uploaded' : ''}`}
            >
                {!fileData.status && <> {done === null && <>
                    <label htmlFor={name} required>
                        <Flex
                            justifyContent={'center'}
                            width='100%'
                            marginTop="3px"
                        >
                            <BsFillCloudUploadFill size={'15px'} />
                        </Flex>
                        <Text width={"100%"} textAlign='center' > Choose file from here</Text>
                        {error.length > 0 && <Text width={"100%"} textAlign='center' color='red' fontSize={'12px'}> {error}</Text>}
                        <a target="_blank"
                            href=" https://workdrive.zohopublic.in/file/9hud68fc2f7d6f4df4a5e87cd59b193579b20"
                            className='sample-file' download>Download Blank Application Form <DownloadIcon /></a>

                    </label>

                    <input type='file' id={name} onChange={handleFileChange}
                        name={name} className="file" required /></>}
                    {
                        done !== null &&
                        <Flex className='uploading'>
                            <Text mb={1}>Uploading...</Text>
                            <div className={'progress-bar'}>
                                <div className={'progress-done'} style={progressStyle}></div>
                            </div>
                            <Text mt={1}>{done} %</Text>
                        </Flex>
                    }</>}
                {
                    fileData.status && <>
                        <Text>{fileData.name}</Text>
                        <CloseIcon color="red.300" onClick={() => {
                            onClose({ target: { ...fileData, name, file_id: fileData.key } });
                            setFileData({ status: false, name: '' })
                        }} />
                    </>
                }
            </Flex>
        </div>


    );
};

export default DocumentUpload;




