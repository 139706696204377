import React from "react";
import Input from "./input";
import RadioButtons from "./radioButtons";
import Select from "./select";
import Textarea from "./textArea";
import Currency from "./currency";
function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "currency":
      return <Currency {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
