import * as React from "react";

const Filter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    viewBox="0 0 256 256"
    xmlSpace="preserve"
    {...props}
    style={{
      marginTop:'16px'
    }}
  >
     
        <path
          d="M38.047 90a3 3 0 0 1-3-3V45.698L4.044 4.813A3.002 3.002 0 0 1 6.435 0h77.129a2.999 2.999 0 0 1 2.391 4.813L54.952 45.698v32.873a3 3 0 0 1-1.445 2.565l-13.904 8.429a3.007 3.007 0 0 1-1.556.435zM12.475 6l27.963 36.877c.396.521.609 1.158.609 1.813v36.984l7.905-4.792V44.689c0-.654.214-1.291.609-1.813L77.524 6H12.475zm39.477 72.571h.01-.01z" 
        />
  </svg>
);

export default Filter;