import React from "react";
import styles from "./input.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Input = () => {
    return(
        <>Input
        <div className={cx('input')}></div>

        </>
    )
}

export default Input