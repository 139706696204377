import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { allEndPoints } from "../../services";

function ResetPassword() {
  const [resetpassworData, setResetpassworData] = useState({
    password: "",
    password_confirmation: "",
  });
  const { forget_id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);

  const resetpassworDataHandal = (event) => {
    setResetpassworData((prev) => {
      prev = { ...prev };
      prev[event.target.name] = event.target.value;
      return prev;
    });
  };
  const handleFormData = (event) => {
    event.preventDefault();
    if (resetpassworData && resetpassworData.email === "") {
      toast({
        status: "error",
        title: "Email field is required",
        position: "top",
      });
      return false;
    }
    setIsLoad((prev) => !prev);
    allEndPoints
      .resetPassword({ ...resetpassworData, token: forget_id })
      .then((res) => {
        toast({
          status: "success",
          title: "Password reset successfully",
        });
        navigate("/signin");
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "Your password does't match",
        });
      });
  };

  return (
    <form onSubmit={handleFormData} id="forgotPassword">
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            Enter new password
          </Heading>
          <FormControl id="password" isRequired>
            <FormLabel> Password</FormLabel>
            <Input
              placeholder=""
              _placeholder={{ color: "gray.500" }}
              type="password"
              name="password"
              value={resetpassworData.password}
              onChange={resetpassworDataHandal}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>confirm Password</FormLabel>
            <Input
              type="password"
              name="password_confirmation"
              value={resetpassworData.password_confirmation}
              onChange={resetpassworDataHandal}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </form>
  );
}

export default ResetPassword;
