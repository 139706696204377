import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function*   morCompanyDocuments(data) {
  try {
    const result = yield call(allEndPoints.morCompanyDocuments, data.deal_id);
    console.log('ruhihhhjhgkfdjhgkjghfkgjhgkjhk',result)
    yield put(actions.recieveMorCompanyDocuments(result.data));
  } catch (error) {
    yield put(actions.failMorCompanyDocuments);
  }
}

function* watchMorCompanyDocumentsSagas() {
  yield takeEvery(
    "FETCH_MOR_COMPANY_DOCUMENTS",
    morCompanyDocuments
  );
}

const morCompanyDocumentsSagas = [fork(watchMorCompanyDocumentsSagas)];

export default morCompanyDocumentsSagas;