import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* partnerApplicationDetails(data) {
  try {
    const result = yield call(allEndPoints.applicationDetails, data.deal_id);
    yield put(actions.recievePartnerApplicationDetails(result.data.data));
  } catch (error) {
    yield put(actions.failPartnerApplicationDetails);
  }
}

function* watchPartnerApplicationDetails() {
  yield takeEvery(
    "FETCH_PARTNER_APPLICATION_DETAILS",
    partnerApplicationDetails
  );
}

const applicaionDetailsSagas = [fork(watchPartnerApplicationDetails)];

export default applicaionDetailsSagas;
