import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* morSolList(data) {
  try {
    const result = yield call(
      allEndPoints.morSolList,
      data.querry
    );
    yield put(actions.recieveMorSolList({ ...result.data }));
  } catch (error) {
    yield put(actions.failMorSolList);
  }
}

function* watchMorSolList() {
  yield takeEvery("FETCH_MOR_SOL_LIST",morSolList);
}

const morSolListSagas = [fork(watchMorSolList)];

export default morSolListSagas;