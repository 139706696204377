import React, { useEffect } from "react";
import { Box, Heading, SimpleGrid, Text, Divider, Stack, Flex } from "@chakra-ui/react";

import { Header } from "../header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../../state";
import { FullLoader } from "../../../../components";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { fetchPartnerApplicationCounts } = bindActionCreators(
    actions,
    dispatch
  );

  const state = useSelector((state) => state);

  const navigate = useNavigate();
  const returnToDashboard = (status,stage='') => {
    let path = `/application/list?status=${status}&stage=${stage}&page=1&limit=10`;

    navigate(path);
  };

  useEffect(() => {
    fetchPartnerApplicationCounts();
  }, []);

  return (
    <>
      {state.partnerApplicationCounts.loading && <FullLoader />}
      <Header />

      <Box>
        <SimpleGrid
          bg="#E7E7E7"
          columns={{ sm: 2, md: 5 }}
          spacing="8"
          p="10"
          pb="590"
          color="#FFFFFF"
          boxShadow="lg"
          h="686px"
        >
          <Box>
            <Box
              boxShadow="lg"
              p="4"
              rounded="md"
              bg="#6f62e4"
              onClick={() => returnToDashboard("preapproval")}
            >
              <Heading as="h2" size="2xl">
                {state.partnerApplicationCounts.data &&
                  state.partnerApplicationCounts.data.preapproval &&
                  state.partnerApplicationCounts.data.preapproval.total}
              </Heading>
              <Text mt="4px" fontSize="sm">
                Preapproval
              </Text>
            </Box>
            <Box >
              <Stack direction='row' h={'200px'} p={2}>
                <Divider orientation='vertical' borderColor={"black"} />
                <Box>
                  {
                    state.partnerApplicationCounts.data &&
                    state.partnerApplicationCounts.data.preapproval &&
                    state.partnerApplicationCounts.data.preapproval.stages &&
                    Object.entries(state.partnerApplicationCounts.data.preapproval.stages).map(([key, val], i) => (

                      <Text fontSize={'xs'} color="black" marginTop={2} cursor="pointer" _hover={{
                        textDecoration: "underline"
                      }}
                      onClick={() => returnToDashboard("preapproval",key)}
                      >{key} : <div style={{fontWeight:"bold"}}>{val}</div></Text>
                    ))
                  }
                </Box>
              </Stack>
            </Box>
          </Box>

          <Box>
            <Box
              boxShadow="lg"
              p="4"
              rounded="md"
              bg="#4176FF"
              onClick={() => returnToDashboard("active")}
            >
              <Heading as="h2" size="2xl">
                {state.partnerApplicationCounts.data &&
                  state.partnerApplicationCounts.data.active &&
                  state.partnerApplicationCounts.data.active.total}
              </Heading>
              <Text mt="4px" fontSize="sm">
                Active Application
              </Text>
            </Box>
            <Box >
              <Stack direction='row' h={'200px'} p={2}>
                <Divider orientation='vertical' borderColor={"black"} />
                <Box>
                  {
                    state.partnerApplicationCounts.data &&
                    state.partnerApplicationCounts.data.active &&
                    state.partnerApplicationCounts.data.active.stages &&
                    Object.entries(state.partnerApplicationCounts.data.active.stages).map(([key, val], i) => (

                      <Text fontSize={'xs'} color="black" marginTop={2} cursor="pointer" _hover={{
                        textDecoration: "underline"
                      }}
                      onClick={() => returnToDashboard("active",key)}
                      >{key} : <div style={{fontWeight:"bold"}}>{val}</div></Text>
                    ))
                  }
                </Box>
              </Stack>
            </Box>
          </Box>

          <Box>
            <Box
              boxShadow="lg"
              p="4"
              rounded="md"
              bg="#49D256"
              onClick={() => returnToDashboard("approved")}
            >
              <Heading as="h2" size="2xl">
                {state.partnerApplicationCounts.data &&
                  state.partnerApplicationCounts.data.trading &&
                  state.partnerApplicationCounts.data.trading.total}
              </Heading>
              <Text mt="4px" fontSize="sm">
                Trading
              </Text>
              
            </Box>
            <Box >
              <Stack direction='row' h={'200px'} p={2}>
                <Divider orientation='vertical' borderColor={"black"} />
                <Box>
                  {
                    state.partnerApplicationCounts.data  &&
                    state.partnerApplicationCounts.data.trading &&
                    state.partnerApplicationCounts.data.trading.stages &&
                    Object.entries(state.partnerApplicationCounts.data.trading.stages).map(([key, val], i) => (

                      <Text fontSize={'xs'} color="black" marginTop={2} cursor="pointer" _hover={{
                        textDecoration: "underline"
                      }}
                      onClick={() => returnToDashboard("trading",key)}
                      >{key} : <div style={{fontWeight:"bold"}}>{val}</div></Text>
                    ))
                  }
                </Box>
              </Stack>
            </Box>
            
            
            
          </Box>
          <Box>
          <Box
              boxShadow="lg"
              p="4"
              rounded="md"
              bg="#FF753A"
              onClick={() => returnToDashboard("declined")}
            >
              <Heading as="h2" size="2xl">
                {state.partnerApplicationCounts.data &&
                  state.partnerApplicationCounts.data.lost &&
                  state.partnerApplicationCounts.data.lost.total}
              </Heading>
              <Text mt="4px" fontSize="sm">
                Declined Application
              </Text>
            </Box>
            <Box >
              <Stack direction='row' h={'200px'} p={2}>
                <Divider orientation='vertical' borderColor={"black"} />
                <Box>
                  {
                    state.partnerApplicationCounts.data  &&
                    state.partnerApplicationCounts.data.lost &&
                    state.partnerApplicationCounts.data.lost.stages &&
                    Object.entries(state.partnerApplicationCounts.data.lost.stages).map(([key, val], i) => (

                      <Text fontSize={'xs'} color="black" marginTop={2} cursor="pointer" _hover={{
                        textDecoration: "underline"
                      }}
                      onClick={() => returnToDashboard("lost",key)}
                      >{key} : <div style={{fontWeight:"bold"}}>{val}</div></Text>
                    ))
                  }
                </Box>
              </Stack>
            </Box>
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default Dashboard;
