import { Field, ErrorMessage } from "formik";
import React from "react";
import textError from "./textError";

function Select(props) {
  const { name, options, ...rest } = props;
  return (
    <div className="form-control">
      <Field
        style={{ height: "30px" }}
        as="select"
        id={name}
        name={name}
        {...rest}
      >
        <option value="" key="select the country">
          select the country
        </option>
        {options.map((opt, optIndex) => (
          <option value={opt.label} key={optIndex}>
            {opt.label}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component={textError} />
    </div>
  );
}

export default Select;
