import React, { useState, useEffect } from "react";
import {
  Table,
  Box,
  Text,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  TableContainer,
  Divider,
  Tfoot,
  Select,
  Center,
  Input,
} from "@chakra-ui/react";
import {
  HedingDiv,
  PaginationDiv,
  SearchBtton,
  SearchFields,
  TableDiv,
} from "./style";
import GPagination from "./Pagination/GPagination";
import { ApplicationDetails } from "../../applicationDetails";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../state";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { FullLoader } from "../../../components";
import MorAddUrl from "./morAddUrl";
import { allEndPoints } from "../../../services";

import ShowUrls from "./ShowUrls";
import AddRequest from "./addRequest";

import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import DownloadAttachment from "./downloadAttachment";
import { useDrawerHook } from "../../../hooks";

const MorApprovalForm = ({ open, onClose, isLoading }) => {
  const [formData, setFormData] = useState({
    page: 1,
    size: 10,
  });
  const [ handleDrawerUrl ] = useDrawerHook()
  const [search, setSearch] = useState(false);
  const [isShowUrls, setShowUrls] = useState(false);
  const [isAddRequest, setAddRequest] = useState(false);
  const dispatch = useDispatch();
  const { fetchMorApplicationsList, fetchMorSolList, fetchLoginPartnerInfo, fetchMorShowUrlReq, fetchMorPartnerSolList } = bindActionCreators(
    actions,
    dispatch
  );
  const [isDownloadAttachment, setIsDownloadAttachment] = useState(false)
  const [isCompanyDocuments, setIsCompanyDocuments] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const state = useSelector((state) => state);
  const [urlList, setUrlList] = useState([]);
  const [solutionName, setSolutionName] = useState([]);
  const [companyId, setCompanyId] = useState([]);


  const getDate = (date) => {
    let newDate = new Date(date)
    if (newDate) {
      return date
    }
    return `${newDate.getFullYear()}-${newDate.getMonth() < 10 ?
      '0' : ''}${newDate.getMonth()}-${newDate.getDate() < 10 ? '0' : ''}${newDate.getDate()}`
  }

  const getQuerries = (type = "str") => {
    let qrStr = "";
    let qrObj = {};
    let qpList = ["page", "solutions", "search"];
    qpList.forEach((qp) => {
      if (searchParams.get(qp)) {
        let val =
          qp === "page"
            ? parseInt(searchParams.get(qp)) - 1
            : searchParams.get(qp);
        qp = qp === "page" ? "offset" : qp;
        if (qp === "offset") { qrStr = qrStr + `${qp}=${val * 10}&`; } else {
          qrStr = qrStr + `${qp}=${val}&`;

        }
        qrObj[qp] = searchParams.get(qp);

      }
    });
    console.log(qrObj)
    if (type === "obj") {
      return qrObj;
    } else {
      return qrStr;
    }
  };

  const handleFormData = (event) => {
    console.log("formdata", event.target.value);
    setFormData((prev) => {
      prev = { ...prev };
      prev[event.target.name] = event.target.value;
      return prev;
    });
  };


  useEffect(() => {
    fetchMorSolList();
  }, []);

  useEffect(() => {
    fetchMorPartnerSolList();
  }, []);

  useEffect(() => {
    console.log(location)
  }, [search])


  const SearchFilter = () => {
    console.log(formData);
    navigate({
      pathname: "",
      search: createSearchParams({
        ...formData,
        page: 1,
        limit: 10,
      }).toString(),
    });
  };

  useEffect(() => {
    fetchLoginPartnerInfo();
    const qrStr = getQuerries();
    if (!searchParams.get('mor_id') && !isShowUrls) {
      fetchMorApplicationsList(qrStr);
    }
  }, [searchParams]);
  // console.log(fetchMorApplicationsList)




  const handleShowUrl = (id) => {
    // navigate({
    //   pathname: "",
    //   search: createSearchParams({
    //     'mor_id': id
    //   }).toString(),
    // });
    handleDrawerUrl('show',`mor_id=${id}`)
  }

 
  useEffect(()=>{
    console.log(location.search)
  },[location])

  const handleCompanyDocuments = (id) => {
    // navigate({
    //   pathname: "",
    //   search: `${location.search}&mor_documents=${id}`
    // });
    handleDrawerUrl('show',`mor_documents=${id}`)

  }


  return (
    <>
      <div style={{ background: "white" }}>
        <span>
          <Box bg="#FFFFFF" w="60%" height="5%" p={4} color="black">
            <Text
              style={{ fontSize: "30px", fontWeight: "bold", marginLeft: "10px" }}
            >
              Merchant Of Records
            </Text>
          </Box>

          <Divider />
        </span>

        <HedingDiv>
          <span >
            <h2 style={{ marginLeft: "12px" }}>Company List</h2>
          </span>
          <span
            style={{
              display: "flex",
              padding: "8px",
              justifyContent: "flex-end",
            }}
          >
            <SearchBtton>
              <Button size="md" variant="solid" width="200%" onClick={() => setSearch(true)}>
                {" "}
                Add {" "}
              </Button>
            </SearchBtton>
            {search && <MorAddUrl open={search} onClose={() => setSearch(false)} />}
          </span>
        </HedingDiv>

        <HedingDiv>
          <SearchFields>
            <div className="filter-field">
              <span>
                <Select
                  variant="filled"
                  placeholder="Select Solutions"
                  size="md"
                  name="solutions"
                  value={formData.solutions}
                  onChange={handleFormData}
                >
                  {state.morPartnerSolList.data &&
                    state.morPartnerSolList.data.total < 1 ? (
                    <h2>"No records found"</h2>
                  ) : (
                    state.morPartnerSolList.data &&
                    state.morPartnerSolList.data.data &&
                    state.morPartnerSolList.data.data.map((d) => {
                      return (
                        <option value={d.code}>
                          {d.name}
                        </option>
                      );
                    })
                  )}
                </Select>
              </span>

              <span>
                <Input placeholder='Company Name' size='md' name="search" onChange={handleFormData}
                />
              </span>


            </div>
            <div className="search-btn">
              <Button size="md" variant="solid" onClick={SearchFilter}>
                Search
              </Button>

              <Button size="md" variant="solid" onClick={() => {
                setFormData('');
                // navigate('');
                navigate({
                  pathname: "",
                  search: createSearchParams({
                      page: 1,
                      limit: 10,
                  }).toString(),
              });
              window.location.reload();
              }}>
                Clear
              </Button>
            </div>
          </SearchFields>
        </HedingDiv>

        <div>
          <Box marginBlockStart={"5px"} h="100%" w="98%" marginLeft="1%">
            <div style={{ padding: "2px" }}>
              <TableDiv>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Date</Th>
                        <Th>Company Name</Th>
                        <Th>Solution</Th>
                        <Th>View Document</Th>
                        <Th>Action</Th>
                        <Th>Add Request</Th>
                      </Tr>
                    </Thead>
                    {state.morApplicationsList.loading && (
                      <FullLoader />
                    )}
                    <Tbody>
                      {state.morApplicationsList.data &&
                        state.morApplicationsList.data.total < 1 ? (
                        <Tr>
                          <Center w="321%" h="100px">
                            <h2>"No records found"</h2>
                          </Center>
                        </Tr>
                      ) : (
                        state.morApplicationsList.data &&
                        state.morApplicationsList.data.data &&
                        state.morApplicationsList.data.data.map((d) => {
                          return (
                            <Tr>
                              <Td>{getDate(d.created_at)}</Td>

                              <Td>{d.name || "N/A"}</Td>
                              <Td>{d.solution}</Td>

                              <Td className="action"
                                onClick={() => {
                                  handleCompanyDocuments(d.id)
                                  setIsCompanyDocuments(true)
                                }}
                              >
                                <ViewIcon />
                                Documents
                              </Td>


                              <Td
                                className="action"
                                onClick={() => {
                                  setShowUrls(true)
                                  handleShowUrl(d.id)
                                }}
                              >
                                Show Url List
                              </Td>


                              <Td
                                className="action"
                                onClick={() => {
                                  setAddRequest(true);
                                  setSolutionName(d);
                                  setCompanyId(d.id);

                                }}
                              >
                                Add Request Urls
                                <AddRequest
                                  open={isAddRequest}
                                  onClose={() => setAddRequest(false)}
                                  companyId={companyId}
                                  solutionName={solutionName}
                                />
                              </Td>

                            </Tr>
                          );
                        })
                      )}
                    </Tbody>

                    <Tfoot>
                      <PaginationDiv>
                        <GPagination
                          currentPage={
                            searchParams.get("page")
                              ? parseInt(searchParams.get("page"))
                              : 1
                          }
                          totalCount={
                            state.morApplicationsList.data
                              ? state.morApplicationsList.data.FilterdRecordCount
                              : 0
                          }
                          pageSize={
                            searchParams.get("limit") ? searchParams.get("limit") : 10
                          }
                          onPageChange={(page) => {
                            console.log(page)
                            setShowUrls(false)
                            let qObj = { ...getQuerries("obj") };
                            delete qObj["page"];
                            navigate({
                              pathname: "",
                              search: createSearchParams({
                                page,
                                limit: 10,
                                ...getQuerries("obj"),
                              }).toString(),
                            });
                            console.log(state.morApplicationsList.data.TotalRecordCount)
                            console.log(page)
                          }}
                        />
                      </PaginationDiv>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </TableDiv>
              {searchParams.get("application_id") && (
                <ApplicationDetails
                  isOpen={searchParams.get("application_id")}
                  onClose={() => {
                    navigate(-1);
                  }}
                  applications={state.partnerApplicationDetails.data}
                />
              )}
            </div>
          </Box>
        </div>
      </div>

      <DownloadAttachment
        open={searchParams.get('mor_documents') ? true : false}
        onClose={() => {
          handleDrawerUrl('close',`mor_documents=${searchParams.get('mor_documents')}`)
        }}

      />

      <ShowUrls
        open={searchParams.get('mor_id')}
        isLoading={isLoading}
        // id={d.id}
        // urls={d.urls}
        // sector={d.sector}
        // isOpen={searchParams.get("id")}
        onClose={() => {
          // navigate('/my-trading-account-list')
          handleDrawerUrl('close',`mor_id=${searchParams.get('mor_id')}`)

        }
        }
        urlList={urlList}
      />
    </>
  );
};

export default MorApprovalForm;