import React from "react";
import { Input } from "@chakra-ui/react";

const Inputs = ({ id, placeholder, ...props }) => {
  return (
    <Input
      height="48px"
      id={id}
      placeholder={placeholder}
      borderColor="black"
      _hover={{
        borderBottom: "1px solid black",
      }}
      _focus={{
        borderBottom: "1px solid black",
      }}
      {...props}
    />
  );
};

export default Inputs;
